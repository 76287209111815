
import {
  defineComponent
} from "@/components/CyanPageWrapper.vue";
import GenericPicker from "@/components/GenericPicker.vue";
import cyanRegions from "@/modules/cyanRegions";

export default defineComponent({
  name: "AgentePicker",
  components: {
    GenericPicker,
  },
    computed: {
    seed(): string {
      return this.$route.params.seedPicker+'' || cyanRegions.base;
    }, 
    }
});
